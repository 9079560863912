<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        a) Which of the following structures represents
        <stemble-latex content="$\textit{cis}\text{-1,3-dichlorocyclohexane}?$" />
      </p>

      <p class="mb-2 pl-12">
        <v-img
          src="/img/assignments/laurierSandboxStructuresQuestion3.png"
          alt="Four different structures of dichlorocyclohexane are shown. Structure A has one chlorine in an axial position and a chlorine two carbons away in an equatorial position. Structure B has a chlorine in an equatorial position and the chlorine two carbons away also in an equatorial position. Structure C has one chlorine in an equatorial position and the other chlorine 3 carbons away in an axial position. Structure D has one chlorine in an equatorial position and the other chlorine two carbons away in an axial position."
          max-width="319px"
        />
      </p>

      <v-radio-group v-model="inputs.multipleChoiceAnswer" :disabled="!allowEditing">
        <v-radio v-for="option in options" :key="option.value" class="my-1" :value="option.value">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2 mt-2">b) I think this because:</p>

      <p class="mb-n6">
        <s-textarea v-model="inputs.explainedResponse" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'LaurierSandboxQ3',
  components: {
    STextarea,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        multipleChoiceAnswer: null,
        explainedResponse: null,
      },
      options: [
        {text: '$\\ce{Structure A}$', value: 'A'},
        {text: '$\\ce{Structure B}$', value: 'B'},
        {text: '$\\ce{Structure C}$', value: 'C'},
        {text: '$\\ce{Structure D}$', value: 'D'},
      ],
    };
  },
};
</script>
